// @flow
import React from 'react';
import PropTypes from 'prop-types';
import {Box, Text, Flex, Image, Spacer, Alert, AlertIcon} from '@chakra-ui/react';
import { v4 as uuid4 } from 'uuid';
import withNavigation from '../../main/WithRoutes';
import COLORS from '../colors';
import IconAttribute from '../IconAttributes';
import RelativeRiskPersonChart from '../RelativeRiskPersonChart';
import { getPatientId } from '../../demo/filters';
import { isShowItem } from './report-config';
import InProgressImpl from '../InProgress';
import {isAthenaHealthUrl, isClinicsUrl, isSelfUrl} from "../../main/routes";
import PatientNameNumberKitTopPanel from "./PatientNameNumberKitTopPanel";
import HowToVideo from "../HowToVideo";

type Props = {
  location: any,
  params: any,
  reportTitle: string,
  titleImage: any,
  riskPercentile: number | Array<number>,
  chartsSection: any,
  calculatorSection: any,
  recommendationSection: any,
  explanatorySection: any,
  configTrait: string,
  personChartTitle?: string | Array<string>,
  loading?: boolean,
  isDemo?: boolean,
  patientNum: ?number,
  patientName?: ?string,
  geneticKitId: string,
  geneticRiskInfoText?: string | Array<string> | null,
  printing?: boolean,
  patientAttributesSection: any,
  alertMessage?: string,
  showAlert?: boolean,
  postCalculatorSection?: boolean,
  topMiddlePane: any,
  topRightPane: any,
  xMargin?: ?string,
  hasGeneticTest?: boolean
};

type State = {
  width: number
};

export class GeneticReportImpl extends React.Component<Props, State> {
    static defaultProps = {};
    constructor(props: Props) {
    super(props);
    (this: any).handleResize = this.handleResize.bind(this);
    this.setDisplayConfig();
    this.state = {
      width: window.innerWidth
    };
    this.patientId = getPatientId(props.params, props.location);
    this.isSelfUrl = isSelfUrl(props.location.pathname);
    this.isIdentifiable = isClinicsUrl(props.location.pathname) || isSelfUrl(props.location.pathname) || isAthenaHealthUrl(props.location.pathname);
  }
  state: State;

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setDisplayConfig() {
    this.showRightTopPanel = isShowItem(
      this.props.location,
      this.props.configTrait,
      'showRightTopPanel'
    );
    this.showChartsSection = isShowItem(
      this.props.location,
      this.props.configTrait,
      'showChartsSection'
    );
    this.showRecommendations = isShowItem(
      this.props.location,
      this.props.configTrait,
      'showRecommendations'
    );
    this.showExplanatory = isShowItem(
      this.props.location,
      this.props.configTrait,
      'showExplanatory'
    );
    this.showGeneticRiskTextArea = isShowItem(
      this.props.location,
      this.props.configTrait,
      'showGeneticRiskTextArea'
    );
  }

  handleResize() {
    this.setState({ width: window.innerWidth });
  }

  patientId: number;
  showGeneticRiskTextArea: boolean;
  showRightTopPanel: boolean;
  showChartsSection: boolean;
  showRecommendations: boolean;
  showExplanatory: boolean;
  isIdentifiable: boolean;
  isSelfUrl: boolean;

  render() {
    let xMargin = this.state.width >= 1200 ? '6%' : '1%';
    if (this.props.xMargin !== undefined) xMargin = this.props.xMargin;
    const riskPercentiles = Array.isArray(this.props.riskPercentile)
      ? this.props.riskPercentile
      : [this.props.riskPercentile];
    const chartSections = Array.isArray(this.props.chartsSection)
      ? this.props.chartsSection
      : [this.props.chartsSection];
    const personChartTitles = Array.isArray(this.props.personChartTitle)
      ? this.props.personChartTitle
      : [this.props.personChartTitle];

    let geneticRiskInfoTexts = null;
    if (this.props.geneticRiskInfoText) {
      geneticRiskInfoTexts = Array.isArray(this.props.geneticRiskInfoText)
        ? this.props.geneticRiskInfoText
        : [this.props.geneticRiskInfoText];
    }
    const alertInnerBox = (
      <Alert status="warning" mt={this.props.printing ? 0 : "10px"} borderRadius="10px" style={{whiteSpace: 'pre-wrap'}} >
        {!this.props.printing && <AlertIcon mr={"20px"}/>}
        {this.props.alertMessage}
      </Alert>
    );

    const alertBox = this.showGeneticRiskTextArea && this.props.showAlert && (
      <Box color={COLORS.REPORT_TEXT} ml={this.props.printing ? "0px" : "3%" }>
        {alertInnerBox}
      </Box>);

    const patientBox = (
      <Box>
        {alertBox}
      </Box>
    );

    let topMiddlePanels = null;
    if (this.props.topMiddlePane === 'patient') {
      topMiddlePanels = [patientBox]
    } else if (this.props.topMiddlePane) {
      if (Array.isArray(this.props.topMiddlePane)) {
        topMiddlePanels = this.props.topMiddlePane;
      } else {
        topMiddlePanels = [this.props.topMiddlePane]
      }
    }

    let topRightPanels = undefined;
    if (this.props.topRightPane === 'patient') {
      topRightPanels = [patientBox];
    } else if (this.props.topRightPane) {
      topRightPanels = Array.isArray(this.props.topRightPane)
          ? this.props.topRightPane
          : [this.props.topRightPane];
    }
    const patientPanelOnTop = this.props.printing && this.props.topRightPane === 'patient';

    return (
      <Box
        color={COLORS.REPORT_TEXT}
        sx={{ '@media print': { '@page': { size: 'landscape;' } } }}
      >
        <Flex mb={"10px"} align="center" ml={"7%"} mt={this.props.printing ? "10px" : 0}>
          {/*<Spacer />*/}
          <Text fontSize={24} color="gray.600">
            {this.props.reportTitle}
          </Text>
          <Image src={this.props.titleImage} h="24px" w="24px" ml="20px" />
          {this.props.isDemo && (
            <Text ml="10px" fontSize={18} color="red.200">
              (DEMO)
            </Text>
          )}
          <Spacer />
        </Flex>
        <PatientNameNumberKitTopPanel
          geneticKitId={this.props.geneticKitId}
          isIdentifiable={this.isIdentifiable}
          printing={this.props.printing}
          patientName={this.props.patientName}
          patientNum={this.props.patientNum}
          isMobile={false}
        />
        <Box mx={xMargin}>
          {this.props.loading && <InProgressImpl label="loading..." />}
          {!this.props.loading && (
            <Box>
              <Box my={"20px"} color="gray.500" fontSize={16} w="100%">
                {patientPanelOnTop && patientBox}
                {riskPercentiles.map((riskPercentile, i) => (
                  <Flex mt={"40px"} mb="30px" w="100%" key={uuid4()}>
                    {/* upper left pane, genetic risks */}
                    <Flex
                        minW={"480px"}
                        w={"35%"}
                        maxW={"40%"}
                        align="top">
                      <Box>
                        {personChartTitles[i] && (
                          <Text textAlign="center" color={COLORS.REPORT_TEXT}>
                            {personChartTitles[i]}
                          </Text>
                        )}
                        {!this.props.hasGeneticTest && i === 0 && <Box
                            borderRadius={"20px"}
                            borderColor={'gray.100'}
                            borderWidth={5}
                            bg={COLORS.WARNING_ALERT_BG}
                        >
                          <Box mx={"20px"} mt={"5px"} pb={"20px"} color={COLORS.REPORT_TEXT}>
                            <Alert status="warning" style={{whiteSpace: 'pre-wrap'}} >
                              <Flex>
                                <AlertIcon mr={"20px"}/>
                                <Text fontWeight={"bold"}>NO GENETICS </Text>
                              </Flex>
                            </Alert>
                            <Text ml="15px" my={"10px"}>The results are based solely on clinical data, which may limit their accuracy. </Text>
                            <Text ml="15px" mb={"10px"}>
                              Enhance the risk assessment with an <b>OpenDNA genetic test</b>, providing a more comprehensive and precise evaluation.
                            </Text>
                            <Box >
                              <HowToVideo
                                title={"Order an OpenDNA test via AthenaHealth"}
                                fileName={"order_test_via_athena"}
                                buttonName={"learn how to order an OpenDNA test via AthenaHealth"}
                                duration={"55 sec"}
                              />
                            </Box>
                          </Box>
                        </Box>
                        }
                        {this.props.hasGeneticTest && <RelativeRiskPersonChart
                          percentile={riskPercentile + 1}
                          iconHeight={64}
                          iconWidth={32}
                          title="GENETIC RISK"
                          showText={false}
                          popoverText={
                            geneticRiskInfoTexts ? geneticRiskInfoTexts[i] : null
                          }
                        />}
                      </Box>
                    </Flex>
                    {/* top middle pane  */}
                    {topMiddlePanels && topMiddlePanels[i] && (
                      <Box mx={this.props.printing ? "10px" : "3%"}>
                        {topMiddlePanels[i]}
                      </Box>)
                    }
                    {/* patient attributes in case of multiple genetic risks */}
                    {i === 1 && (<Box ml={"8%"}>
                      {this.props.patientAttributesSection}
                    </Box>)}
                    {this.props.printing ? null : <Spacer/>}
                    {/* top right pane */}
                    {topRightPanels && topRightPanels[i] && !patientPanelOnTop && (
                      <Box ml={this.props.printing ? 0 : "1%"} mr={"0%"}>
                        {topRightPanels[i]}
                      </Box>)
                    }
                  </Flex>
                ))}
                {riskPercentiles.length === 1 &&  this.props.patientAttributesSection}
                {/*{this.props.printing && this.showGeneticRiskTextArea && this.props.showAlert && alertInnerBox}*/}
                {this.showChartsSection && <Box>{chartSections}</Box>}
                {this.props.calculatorSection && (
                  <Box mt="30px">{this.props.calculatorSection}</Box>
                )}
                {this.props.postCalculatorSection && <Box>{this.props.postCalculatorSection}</Box>}
                {this.showRecommendations && this.props.recommendationSection}
                {this.showExplanatory && this.props.explanatorySection}
              </Box>
            </Box>
          )}
          <Box mx="5%">
            <IconAttribute precedingLine />
          </Box>
        </Box>
      </Box>
    );
  }
}

GeneticReportImpl.propTypes = {
  calculatorSection: PropTypes.any,
  personChartTitle: PropTypes.any,
  loading: PropTypes.bool,
  isDemo: PropTypes.bool,
  geneticRiskInfoText: PropTypes.any,
  printing: PropTypes.bool,
  patientName: PropTypes.string,
  alertMessage: PropTypes.string,
  showAlert: PropTypes.bool,
  postCalculatorSection: PropTypes.any,
  patientDetailsOnTopRight: PropTypes.bool,
  xMargin: PropTypes.string,
  hasGeneticTest: PropTypes.bool
};

GeneticReportImpl.defaultProps = {
  calculatorSection: null,
  personChartTitle: null,
  loading: false,
  isDemo: true,
  geneticRiskInfoText: null,
  printing: false,
  patientName: undefined,
  alertMessage: undefined,
  showAlert: false,
  postCalculatorSection: undefined,
  topMiddlePane: 'patient',
  topRightPane: undefined,
  xMargin: undefined,
  hasGeneticTest: true
};

export default withNavigation(GeneticReportImpl);
