// @flow
import React, { useState, useCallback, useEffect } from 'react';
import {
  Stack,
  Text,
  Flex,
  Box,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  FormControl,
  Spacer,
  useDisclosure
} from '@chakra-ui/react';
import queryString from 'query-string';

import { v4 as uuid4 } from 'uuid';
import ActionButton from '../common/ActionButton';
import COLORS from '../common/colors';
import {loadPatients, addPatient, addVisit, addOrder} from '../api/capilots';
import PilotLabAddPatient from './PilotLabAddPatient';
import withNavigation from '../main/WithRoutes';
import PilotPatientCard from './PilotPatientCard';
import { feetInchToCm, poundsToKg } from '../utils/unit_translation';
import { mgdl2mmolL } from '../utils/cholesterol';
import { percents2mmolmol } from '../utils/a1c';
import HeaderRow from "./PilotLabPatientsHeaderRow";
import SearchTextBar from "../common/SearchTextBar";
import {objectContainsText} from "../utils/search";
import getPatientAge from "../utils/age";
import {isClinicsUrl} from "../main/routes";
import {getClinicDisplayName} from "./clinics";
import {resolveComposingOrderNames} from "../utils/test_types";
import InProgressImpl from "../common/InProgress";

type Props = {
  params: Object,
  location: any
};
export function PilotLabPatientListImpl(props: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef();
  const finalRef = React.useRef();
  const [loading, setLoading] = useState(true);
  const [patients, setPatients] = useState([]);
  const isClinicsInUrl = isClinicsUrl(props.location.pathname);

  const isAddingAllowed = useCallback(()=> {
    if (!isClinicsInUrl) return false;
    for (let i = 0; i < patients.length; i++) {
      if (patients[i].emr) return false;
    }
    return true
  }, [patients, isClinicsInUrl]);
  const isPatientAddingEnabled = isAddingAllowed();
  const { params, location } = props;
  // declare the data fetching function
  const fetchData = useCallback(async () => {
    const clinicName = params.lab;
    const doctorName = queryString.parse(location.search).doctor;

    const patientsList = await loadPatients(clinicName, doctorName, isClinicsInUrl ? "patient_name" : null);
    setPatients(patientsList.data);
    setLoading(false);
  }, [params, location, isClinicsInUrl]);
  useEffect(() => {
    fetchData()
      .then()
      // make sure to catch any error
      .catch(console.error);
  }, [fetchData]);
  const onApplyAndClose = useCallback(async (newPatient: any, orders: number) => {
    const gender = newPatient.gender === 'male';
    const status = 'SENT_TO_LAB';
    const kitId = newPatient.state.kitId;
    const response = await addPatient(
      newPatient.name,
      kitId,
      gender,
      newPatient.birthYear,
      newPatient.birthMonth,
      feetInchToCm(newPatient.feet, newPatient.inch),
      params.lab,
      newPatient.doctor,
      status,
      newPatient.externalId,
      newPatient.email,
      newPatient.phone
    );
    if (response.status === 200) {
      await addVisit(
        response.data.patient_id,
        poundsToKg(newPatient.weight),
        newPatient.sbp,
        newPatient.dbp,
        mgdl2mmolL(newPatient.hdl),
        mgdl2mmolL(newPatient.ldl),
        mgdl2mmolL(newPatient.tc),
        newPatient.bmi,
        newPatient.bpTreated,
        newPatient.diabetes,
        newPatient.fhDiabetes,
        percents2mmolmol(newPatient.a1c),
        newPatient.smoker
      );
      const orderNames = resolveComposingOrderNames(orders);
      for (const orderName of orderNames) {
        console.log(`add test order ${orderName}`)
        await addOrder(response.data.patient_id, orderName, kitId);
      }
    }
    await fetchData();
    onClose();
  }, [fetchData, onClose, params]);
  const getClinicName = () => {
    const clinic = props.params.lab;
    return getClinicDisplayName(clinic);
  };
  const onPatientRecordChange = useCallback(async (patientId: ?string) => {
    await fetchData();
  }, [fetchData]);

  const [searchText, setSearchText] = useState(undefined);
  const [searchCaseSensitive, setSearchCaseSensitive] = useState(false);
  const [showDemoPatients, setShowDemoPatients] = useState(false);

  const onSearchTextChange = useCallback( (text: string, isCaseSensitive: boolean, showDemoPatients: boolean) => {
    setSearchCaseSensitive(isCaseSensitive)
    setSearchText(text);
    setShowDemoPatients(showDemoPatients)
  }, []);
  const patientContainsText = useCallback((patient: Object, text: ?string, caseSensitive: boolean) => {
    return objectContainsText(patient, [
        "patient_num",
        "patient_name",
        "kit_id",
        "external_id",
        "gender",
        "status",
        "age"
    ], text, caseSensitive);
  }, []);

  const isDemoPatient = useCallback((patient) => {
    return patient && patient.patient_id && patient.patient_id.startsWith("00000000-0000");
  }, [])

  const getFilteredPatients = useCallback(() => {
    const filteredPatients = [];
    patients.forEach(patient => {
      if (!showDemoPatients && isDemoPatient(patient)) return;
      patient.gender = patient.sex ? "Male": "Female";
      patient.age = getPatientAge(patient.birth_year, patient.birth_month);
      if (patientContainsText(patient, searchText, searchCaseSensitive)) {
        filteredPatients.push(patient);
      }
    });
    return filteredPatients;
  }, [searchText, searchCaseSensitive, patientContainsText, patients, showDemoPatients, isDemoPatient]);
  const filteredPatients = getFilteredPatients();
  return (
    <Box mx="8%">
      <Flex align="center" my="30px">
        <Text fontSize={18} color={COLORS.REPORT_TEXT} fontWeight="bold">
          {getClinicName()}
        </Text>
        <Spacer />
        <ActionButton
          name="New patient..."
          onClick={onOpen}
          color={COLORS.LABEL_TEXT_COLOR}
          isDisabled={!isPatientAddingEnabled}
        />
      </Flex>
      <Box mb={"30px"}>
        <SearchTextBar label={"Filter:"} onSearch={onSearchTextChange}/>
      </Box>
      <Stack fontSize={16} color={COLORS.LABEL_TEXT_COLOR} spacing="5px">
        <HeaderRow isIdentifiable={isClinicsInUrl}/>
        <Divider color="gray.700" mt="2px" />
        {loading && <InProgressImpl label={"loading..."} mt={"50px"}/>}
        {filteredPatients &&
          filteredPatients.length &&
          filteredPatients.map(patient => (
            <Box key={uuid4()}>
              <PilotPatientCard patient={patient} bg="gray.50" allowEditing={isClinicsInUrl && !patient.emr} isIdentifiable={isClinicsInUrl} onPatientRecordChange={onPatientRecordChange}/>
              <Divider color="gray.700" mt="4px" />
            </Box>
          ))}
      </Stack>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent color={COLORS.REPORT_TEXT}>
          <ModalBody color={COLORS.REPORT_TEXT}>
            <FormControl>
              <PilotLabAddPatient
                onCancel={onClose}
                onApply={onApplyAndClose}
              />
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default withNavigation(PilotLabPatientListImpl);
